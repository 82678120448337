import styled from 'styled-components'

type TwoCardContainerProps = {
  cardHeight: number
}

type StoryCardContainerProps = {
  cardHeight: number
}

export const StoryCardContainer = styled.div<StoryCardContainerProps>(({ cardHeight }) => ({
  paddingTop: '2rem',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
  '> div > a': {
    overflow: 'hidden',
    height: cardHeight,
  },
}))

export const TwoCardContainer = styled.div<TwoCardContainerProps>(({ cardHeight }) => ({
  '> div > div > a': {
    overflow: 'hidden',
    height: cardHeight,
  },
}))
