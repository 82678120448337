import type { IBlogFields, IArticleFields } from '@/bb-contentful-types'
import { filterArticlesWithNoLanguageSupport } from '@/blogBuilder/utils/articleUtils'
import { Entry, Sys, createClient, EntryCollection } from 'contentful'
import getConfig from 'next/config'
import { Language } from 'src/siteBuilder/locale'

type FindDate = (publishDate: string, lang: string) => string

type AppendIntcmp = (
  url: string,
  telusDomain: string,
  destinationStoryName: string,
  blogSlug: string,
  blogCategory: string,
  lang: string,
  allStories?: boolean
) => string | undefined

type GetCategory = (lang: Language, blogSlug: string, blogCategory?: string) => any | null

type GetArticles = (lang: Language, categories: any, articles: string[], numberOfArticles: number) => any | null

type GetMissingArticle = (count: number) => any | null

const findDate: FindDate = (publishDate, lang) => {
  const date = new Date(publishDate)

  const frenchMonths = ['Janv', 'Févr', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc']

  const month = date.toString().split(' ').splice(1, 3).join(' ').split(' ')[0]

  const formattedDate =
    lang === 'fr'
      ? `${date.getDate() < 10 ? 0 : ''}${date.getDate()} ${frenchMonths[date.getMonth()]} ${date.getFullYear()}`
      : `${month} ${date.getDate()}, ${date.getFullYear()}`

  return formattedDate
}

const seeAllStoriesLabel = {
  en: 'View related stories',
  fr: 'Voir les articles sur le même sujet',
}

const isFrenchAndEnSupport = (foundArticle: Entry<IArticleFields>, lang: Language) =>
  lang === 'fr' && foundArticle?.fields?.languageSupport === 'en'

const appendIntcmp: AppendIntcmp = (
  url,
  telusDomain,
  destinationStoryName,
  blogSlug,
  blogCategory,
  lang,
  allStories = false
) => {
  if (url && destinationStoryName && blogSlug && lang) {
    const lastSlash = url.lastIndexOf('/')
    const normalizedFrom = url.substring(lastSlash + 1)
    const category = blogCategory ? blogCategory.split(' ').join('-').toLowerCase() : 'all'

    let domain: string, slugOrder: string
    if (blogSlug === 'foundation') {
      domain = 'www.friendlyfuture.com'
      slugOrder = `/${blogSlug}/blog`
    } else {
      domain = 'www.telus.com'
      slugOrder = `/blog/${blogSlug}`
    }

    if (allStories === true) {
      const route = telusDomain
        ? `https://${domain}/${lang}${slugOrder}?category=${category}&INTCMP=tcom_${telusDomain}_${normalizedFrom}_to_${destinationStoryName}`
        : `https://${domain}/${lang}${slugOrder}?category=${category}&INTCMP=tcom_${normalizedFrom}_to_${destinationStoryName}`
      return route
    }
    const route = telusDomain
      ? `https://${domain}/${lang}${slugOrder}/${destinationStoryName}?INTCMP=tcom_${telusDomain}_${normalizedFrom}_to_${destinationStoryName}`
      : `https://${domain}/${lang}${slugOrder}/${destinationStoryName}?INTCMP=tcom_${normalizedFrom}_to_${destinationStoryName}`
    return route
  }
  // Default if not all parameters are passed
  return undefined
}

const config = getConfig()?.publicRuntimeConfig

const client = createClient({
  space: config.CONTENTFUL_BLOG_BUILDER_SPACE,
  accessToken: config.CONTENTFUL_BLOG_BUILDER_DELIVERY_TOKEN,
  host: config.CONTENTFUL_BLOG_BUILDER_CLIENT_CONFIG.host,
})

const getCategory: GetCategory = async (lang, blogSlug, blogCategory) => {
  try {
    const categories: EntryCollection<IBlogFields> = await client.getEntries({
      content_type: 'blog',
      'fields.slug': blogSlug,
      select: 'fields.categories',
      include: blogCategory ? 2 : 3,
      locale: `${lang}-CA`,
      limit: 1,
    })
    const allCategories = blogCategory
      ? categories.includes.Entry.filter((d) => d.fields.articles).filter(
          (entry: { fields: { name: string } }) =>
            entry.fields.name.trim().toLowerCase() === blogCategory.trim().toLowerCase()
        )
      : categories.items[0].fields.categories

    return allCategories
  } catch (error) {
    return null
  }
}

const getArticlesWithNoCat: GetArticles = async (lang, categories, articles, numberOfArticles) => {
  const cateArticlesFlattened = categories
    .flatMap((obj: { fields: { articles: any } }) => obj?.fields?.articles)
    .filter((d) => d?.fields)

  cateArticlesFlattened.sort(
    (
      a: { fields: { publishDate: string | number | Date } },
      b: { fields: { publishDate: string | number | Date } }
    ) => {
      const aPublishDate = new Date(a?.fields?.publishDate).valueOf()
      const bPublishDate = new Date(b?.fields?.publishDate).valueOf()

      return bPublishDate - aPublishDate
    }
  )
  const overrideArticles =
    articles &&
    (await client.getEntries<IArticleFields>({
      content_type: 'article',
      'fields.slug[in]': articles?.join(),
      locale: `${lang}-CA`,
      limit: 4,
    }))

  const selectedArticles = overrideArticles?.items?.map((d) => d)

  const filtered = filterArticlesWithNoLanguageSupport(cateArticlesFlattened, lang)

  const finalArticles = selectedArticles
    ? [...selectedArticles, ...filtered].slice(0, numberOfArticles)
    : filtered.slice(0, numberOfArticles)

  return finalArticles
}

const getArticles: GetArticles = async (lang, categories, articles, numberOfArticles) => {
  try {
    const cateArticles = categories.map((category: { fields: { name: any; articles: any[] } }) => {
      return {
        category: category.fields.name,
        articleIds: category.fields.articles
          .sort((a, b) => {
            const aPublishDate = new Date(a?.fields?.publishDate).valueOf()
            const bPublishDate = new Date(b?.fields?.publishDate).valueOf()

            return bPublishDate - aPublishDate
          })
          .map((article: { sys: { id: any } }) => article?.sys?.id),
      }
    })

    const cateArticlesFlattened = cateArticles.flatMap((obj: { articleIds: any }) => obj.articleIds)
    // Query articles that are explicitly request to be featured
    const overrideArticles =
      articles &&
      (await client.getEntries<IArticleFields>({
        content_type: 'article',
        'fields.slug[in]': articles.join(),
        locale: `${lang}-CA`,
        limit: 4,
      }))

    const selectedArticleIds = overrideArticles?.items?.map((item) => item?.sys?.id)

    const articleIds = selectedArticleIds
      ? [...selectedArticleIds, ...cateArticlesFlattened.slice(0, numberOfArticles)]
      : [...cateArticlesFlattened.slice(0, numberOfArticles)]
    // Query articles with language support set
    const languageArticles = await client.getEntries<IArticleFields>({
      content_type: 'article',
      'sys.id[in]': articleIds.join(),
      'fields.languageSupport': lang,
      'fields.languageSupport[exists]': 'true',
      locale: `${lang}-CA`,
      order: '-fields.publishDate',
      limit: 4,
    })

    // Query articles without language support (supports both en and fr)
    const nonLanguageArticles = await client.getEntries<IArticleFields>({
      content_type: 'article',
      'sys.id[in]': articleIds.join(),
      'fields.languageSupport[exists]': 'false',
      locale: `${lang}-CA`,
      order: '-fields.publishDate',
      limit: 4,
    })

    // Combine both array of articles, without duplicates
    let articleItems = [...new Set([...languageArticles.items, ...nonLanguageArticles.items])]
    // if numberOfArticles don't match article length fetch more articles
    // this happens because it fetched an article that didn't match the language support
    const getMissingArticle: GetMissingArticle = async (count) => {
      count = count || 1

      const articlesIds = [...cateArticlesFlattened.slice(0, +numberOfArticles + count)]

      const article = articlesIds[articlesIds.length - 1]

      const extraArticle = await client.getEntries<IArticleFields>({
        content_type: 'article',
        'sys.id[in]': article,
        'fields.languageSupport[exists]': 'false',
        locale: `${lang}-CA`,
        order: '-fields.publishDate',
        limit: 4,
      })

      if (extraArticle.items.length > 0) {
        return [...new Set([...languageArticles.items, ...nonLanguageArticles.items, ...extraArticle.items])]
      } else {
        return await getMissingArticle(count + 1)
      }
    }

    if (articleItems.length !== +numberOfArticles) {
      articleItems = await getMissingArticle(1)
    }

    // Sort articles based on date, pull latest 3
    articleItems.sort((a, b) => {
      const aPublishDate = new Date(a.fields.publishDate).valueOf()
      const bPublishDate = new Date(b.fields.publishDate).valueOf()

      return bPublishDate - aPublishDate
    })
    // Reverse array as first item in array should be last item added to front of articleItems
    if (articles) {
      articles.reverse().forEach((slug) => {
        const foundArticle = overrideArticles.items.find((item) => item.fields.slug === slug)

        if (foundArticle && !isFrenchAndEnSupport(foundArticle, lang)) {
          articleItems.unshift(foundArticle)
        }
      })
    }

    // remove dups after selected articles are added
    const removeDupsFromArticles = (arr: Entry<IArticleFields>[], key: keyof Sys) => {
      return [...new Map(arr.map((item) => [item.sys[key], item])).values()]
    }

    const articleItemsFiltered: any[] = removeDupsFromArticles(articleItems.slice(0, 4), 'id')
    // Full list of assets and linked entries to populate top 3 articles
    const overrideAssets = articles && overrideArticles.includes ? overrideArticles.includes.Asset : []
    const languageAssets = languageArticles.includes ? languageArticles.includes.Asset : []
    const nonLaguageAssets = nonLanguageArticles.includes ? nonLanguageArticles.includes.Asset : []

    const overrideEntries = articles && overrideArticles.includes ? overrideArticles.includes.Entry : []
    const languageEntries = languageArticles.includes ? languageArticles.includes.Entry : []
    const nonLaguageEntries = nonLanguageArticles.includes ? nonLanguageArticles.includes.Entry : []

    const assets = articles
      ? [...languageAssets, ...nonLaguageAssets, ...overrideAssets]
      : [...languageAssets, ...nonLaguageAssets]
    const entries = articles
      ? [...languageEntries, ...nonLaguageEntries, ...overrideEntries]
      : [...languageEntries, ...nonLaguageEntries]

    const mappedArticles = articleItemsFiltered.map((article) => {
      const image = assets.find((asset) => asset.sys.id === article.fields.image.sys.id)
      const authorIds = article.fields.authors
        ? article.fields.authors.map((author: { sys: { id: any } }) => author.sys.id)
        : []
      const authors = entries.filter((author) => authorIds.indexOf(author.sys.id) !== -1)
      const seo = entries.find((s) => article.fields.seo.sys.id === s.sys.id)
      const returnObj = { ...article }
      const categoryMap = cateArticles.find(
        (map: { articleIds: string | any[] }) => map.articleIds.indexOf(article.sys.id) !== -1
      )
      returnObj.fields.categoryName = categoryMap ? categoryMap.category : ''
      returnObj.fields.image = image
      returnObj.fields.authors = authors
      returnObj.fields.seo = seo
      return returnObj
    })
    return mappedArticles
  } catch (error) {
    return null
  }
}

const helpers = {
  getArticles,
  getCategory,
  findDate,
  appendIntcmp,
  seeAllStoriesLabel,
  isFrenchAndEnSupport,
  getArticlesWithNoCat,
}

export default helpers
