import { ActivityIndicator } from '@telus-uds/components-web'
import styled from 'styled-components'

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
})

const Loader = () => (
  <Container>
    <ActivityIndicator label="fetching products" />
  </Container>
)

export default Loader
