import { StoryCard as StoryCardAllium } from '@telus-uds/components-web'
import helpers from './helpers'
import Image from '../../core/Image/Image'

type ImageType = {
  fields: {
    description: string
    title: string
    file: {
      url: string
      details: {
        image: {
          width: number
          height: number
        }
      }
    }
  }
}

type Data = {
  image: ImageType
  heroImage: ImageType
}

type FindStoryType = (storyType: string, language: string) => string
type GetImage = (data: Data) => { src: string; alt: string; width: number; height: number }

type Props = {
  displayDate: string
  pathname: string
  language: string
  blogSlug: string
  blogCategory: string
  field: {
    storyType: string
    lineOfBusiness: string
    slug: string
    image: ImageType
    heroImage: ImageType
    publishDate: string
    title: string
    seo: {
      fields: {
        description: string
      }
    }
  }
}

const findStoryType: FindStoryType = (storyType, language) => {
  if (language === 'fr' && storyType === 'Video') return 'vidéo'
  if (storyType) return storyType
  return 'article'
}

const StoryCard = ({ displayDate, pathname, language, field, blogSlug, blogCategory }: Props) => {
  const { appendIntcmp, findDate } = helpers

  const getImage: GetImage = (data) => {
    if (data.image) {
      return {
        src: data?.image?.fields?.file?.url,
        alt: data?.image?.fields?.description,
        width: data?.image?.fields?.file?.details?.image?.width,
        height: data?.image?.fields?.file?.details?.image?.height,
      }
    } else if (data.heroImage) {
      return {
        src: data?.heroImage?.fields?.file?.url,
        alt: data?.heroImage?.fields?.description,
        width: data?.heroImage?.fields?.file?.details?.image?.width,
        height: data?.heroImage?.fields?.file?.details?.image?.height,
      }
    } else return null
  }

  const imageData = getImage(field)

  const href = appendIntcmp(pathname, field.lineOfBusiness, field.slug, blogSlug, blogCategory, language, false)
  return (
    <StoryCardAllium
      testID="story-card"
      href={href ? href : ''}
      tag={findStoryType(field?.storyType, language)}
      date={displayDate === 'no' ? '' : findDate(field.publishDate, language)}
      title={field?.title}
      description={field?.seo?.fields?.description ? field?.seo?.fields?.description : ''}
      fullBleedContent={{
        content: imageData && (
          <Image
            src={imageData.src}
            alt={imageData.alt}
            height={imageData.height}
            width={imageData.width}
            data-testid="responsive-image-svg"
          />
        ),
      }}
    />
  )
}

export default StoryCard
