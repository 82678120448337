import { IArticle } from '@/bb-contentful-types'
import { Language } from '@/siteBuilder/locale'
import { GenericCategory } from '../provider/ArticleProvider'

export const removeDuplicates = (arr: IArticle[]): IArticle[] => {
  const uniqueIds = new Set()
  return arr.reduce((uniqueItems, item) => {
    const itemId = item?.sys?.id
    if (!uniqueIds.has(itemId)) {
      uniqueIds.add(itemId)
      uniqueItems.push(item)
    }
    return uniqueItems
  }, [])
}

export const filterArticles = (articles: IArticle[]): IArticle[] => articles.filter((article) => article.fields)

export const sortArticlesByDate = (articles: IArticle[]) =>
  articles &&
  articles.sort((a, b) => {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b?.fields?.publishDate).valueOf() - new Date(a?.fields?.publishDate).valueOf()
  })

export const filterArticlesWithNoLanguageSupport = (articles: IArticle[], lang: Language) => {
  return articles && articles.filter((item) => !item?.fields?.languageSupport || item?.fields?.languageSupport === lang)
}

export const findArticleCategory = (categories: GenericCategory[], category: string) =>
  categories.find((cat) => cat?.fields?.name?.toLowerCase() === category?.toLowerCase())
