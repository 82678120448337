import { ChevronLink, Typography, Spacer } from '@telus-uds/components-web'
import helpers from './helpers'

type Props = {
  heading: JSX.Element
  text: string
  description: string
  lang: string
  url: string
  textSize: string
}

const HeroContent = ({ lang, text, description, url, textSize, heading }: Props) => {
  return (
    <>
      {heading ? (
        <>{heading}</>
      ) : (
        <Typography variant={{ size: 'h1' }} block heading="h1">
          {text}
        </Typography>
      )}
      <Spacer space={4} />
      <Typography variant={{ size: textSize, colour: 'secondary' }} block>
        {description}
      </Typography>
      <Spacer space={5} />
      <ChevronLink href={url}>
        {lang === 'fr' ? helpers.seeAllStoriesLabel.fr : helpers.seeAllStoriesLabel.en}
      </ChevronLink>
    </>
  )
}

export default HeroContent
